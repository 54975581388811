import React from "react";
import styles from "./Footer.module.scss";
import logo from "../assets/images/logo.png";
import linkedin from "../assets/images/linkedin.png";
import twitter from "../assets/images/twitter.png";
import { NavLink } from "react-router-dom";

function Footer() {
    return (
        <footer>
            <div className={`${styles.footer} d-flex flex-row justify-content-space-between align-items-center`}>
                <NavLink to="/">
                    <img src={logo} alt="Logo FDevelopment" className={styles.logofooter} />
                </NavLink>
                <div>
                    <a href="https://twitter.com/@Fdevelopment" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="icon de twitter" className={styles.icon_footer} />
                    </a>
                    <a href="https://linkedin.com/company/fdevelopment" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="icon de linkedin" className={styles.icon_footer} />
                    </a>
                </div>
                <div>
                    <a href="mailto:contact@fdevelopment.eu" className="mail">contact@fdevelopment.eu</a>
                </div>
                <div>
                    <p className={`${styles.copyright} d-flex justify-content-center align-items-center`}>Développé par Céline L.< br />Tous droits réservés</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;